html,
body {
  margin: 0px;
  padding: 0px;
}

.row {
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.row.center {
  justify-content: center;
}
.row.space-between {
  justify-content: space-between;
}
.row.flex-end {
  justify-content: flex-end;
}
.col {
  display: flex;
  flex-direction: column;
}
.col.center {
  align-items: center;
}
.form-container {
  border: 2px solid black;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
}
